import { IconFileDescription} from '@tabler/icons-react';
const getDocMenu = () => {
  return {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'exploration_center',
        title: '接口文档',
        type: 'collapse',
        icon: IconFileDescription,
        breadcrumbs: false,
        isAdmin: false,
        children: [
          {
            id: 'chat_bot',
            title: '文本对话',
            type: 'item',
            url: '/panel/text-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          // {
          //   id: 'text2image',
          //   title: '图片生成',
          //   type: 'item',
          //   url: '/panel/img-doc',
          //   icon: IconFileDescription,
          //   breadcrumbs: false,
          //   isAdmin: false
          // },
          // {
          //   id: 'text2language',
          //   title: '语音生成',
          //   type: 'item',
          //   url: '/panel/audio-doc',
          //   icon: IconFileDescription,
          //   breadcrumbs: false,
          //   isAdmin: false
          // },
          {
            id: 'model-list',
            title: '模型列表',
            type: 'item',
            url: '/panel/model-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          }, {
            id: 'account',
            title: '账户余额',
            type: 'item',
            url: '/panel/account-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'error-num',
            title: '错误码',
            type: 'item',
            url: '/panel/error-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          }
        ],
        group: ''
      },
      {
        id: 'question',
        title: '常见问题',
        type: 'collapse',
        url: '/panel/question-doc',
        icon: IconFileDescription,
        breadcrumbs: false,
        children: [
          {
            id: 'ticket',
            title: '如何开发票',
            type: 'item',
            url: '/panel/ticket-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'accountAbnormal',
            title: '账户异常',
            type: 'item',
            url: '/panel/abnormal-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'balanceOverdue',
            title: '余额过期',
            type: 'item',
            url: '/panel/overdue-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'private',
            title: '私有化部署',
            type: 'item',
            url: '/panel/private-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'vouchers',
            title: '如何使用兑换券',
            type: 'item',
            url: '/panel/vouchers-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'password',
            title: '余额不足',
            type: 'item',
            url: '/panel/insufficient-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'password',
            title: '密码丢失怎么办？',
            type: 'item',
            url: '/panel/password-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'captcha',
            title: '手机收不到验证码怎么办？',
            type: 'item',
            url: '/panel/captcha-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'website',
            title: '网站打不开',
            type: 'item',
            url: '/panel/website-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'authentication',
            title: '实名认证',
            type: 'item',
            url: '/panel/authentication-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'negativeNumber',
            title: '为什么账户余额会扣为负数？',
            type: 'item',
            url: '/panel/negative-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'deductionOrder',
            title: '可用余额中的扣款顺序',
            type: 'item',
            url: '/panel/deduction-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          }
        ],
        group: ''
      },
      // {
      //   id: 'logs',
      //   title: '公告日志',
      //   type: 'collapse',
      //   icon: IconFileDescription,
      //   breadcrumbs: false,
      //   children: [
      //     {
      //       id: 'logs',
      //       title: 'V1.0.0',
      //       type: 'item',
      //       url: '/panel/logsV1.0.0-doc',
      //       icon: IconFileDescription,
      //       breadcrumbs: false,
      //       isAdmin: false
      //     },
      //     {
      //       id: 'logs',
      //       title: 'V1.0.2',
      //       type: 'item',
      //       url: '/panel/logsV1.0.2-doc',
      //       icon: IconFileDescription,
      //       breadcrumbs: false,
      //       isAdmin: false
      //     },
      //     {
      //       id: 'logs',
      //       title: 'V1.1.0',
      //       type: 'item',
      //       url: '/panel/logsV1.1.0-doc',
      //       icon: IconFileDescription,
      //       breadcrumbs: false,
      //       isAdmin: false
      //     },
      //     {
      //       id: 'logs',
      //       title: 'V1.1.1',
      //       type: 'item',
      //       url: '/panel/logsV1.1.1-doc',
      //       icon: IconFileDescription,
      //       breadcrumbs: false,
      //       isAdmin: false
      //     },
      //     {
      //       id: 'logs',
      //       title: 'V1.1.2',
      //       type: 'item',
      //       url: '/panel/logsV1.1.2-doc',
      //       icon: IconFileDescription,
      //       breadcrumbs: false,
      //       isAdmin: false
      //     },
      //     {
      //       id: 'logs',
      //       title: 'V1.2.0',
      //       type: 'item',
      //       url: '/panel/logsV1.2.0-doc',
      //       icon: IconFileDescription,
      //       breadcrumbs: false,
      //       isAdmin: false
      //     }
      //   ],
      // },

    ]
  };
};

export { getDocMenu };
