export const filterMenu = (menuList, permissions) => {
  return menuList.filter((item) => {
    // 先检查该项是否在权限列表中
    const hasPermission = item.type === 'collapse' ? true : (permissions || [])?.includes(item.key);

    // // 如果是 collapse 类型，且没有权限或没有子项，则排除
    if (item.type === 'collapse') {
      // 如果没有子项或者子项没有权限，则排除该 collapse 项
      item.children = item.children && item.children.length > 0 ? filterMenu(item.children, permissions) : [];
      return hasPermission && item.children.length > 0;
    }
    // 对于非 collapse 类型的项，直接检查权限
    return hasPermission;
  });
};
