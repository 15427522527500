import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Typography, ButtonBase, useMediaQuery } from '@mui/material';

// project imports
import ProfileSection from './ProfileSection';
import { useSelector } from 'react-redux';
// assets
import { IconMenu2 } from '@tabler/icons-react';

import logo from 'assets/images/auth/logo.svg';
import question from 'assets/images/auth/question.svg';
import Ordinary from 'assets/images/rights/ordinary.png';
import Senior from 'assets/images/rights/senior.png';
import Major from 'assets/images/rights/major.png';
import Private from 'assets/images/rights/private.png';
import { useNavigate } from 'react-router';
import { API } from '../../../utils/api';
import { showError } from '../../../utils/common';

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const siteInfo = useSelector((state) => state.siteInfo);
  const navigate = useNavigate();
  const [rightsLevel, setRightsLevel] = useState(0);
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const getUserRight = async () => {
    const res = await API.get('/api/user/rights');
    const { success, message, data } = res.data;
    if (success) {
      setRightsLevel(data);
    } else {
      showError(message);
    }
  };

  const handleRightsIcon = () => {
    switch (rightsLevel) {
      case 1:
        return Senior;
      case 2:
        return Major;
      case 3:
        return Private;
      default:
        return Ordinary;
    }
  };

  const shipRights = () => {
    navigate('/profile/rights');
  };

  useEffect(() => {
    getUserRight().then();
  }, []);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          {/* <LogoSection /> */}
          <img
            src={logo}
            alt="logo"
            style={{ height: '22px', margin: '17px 0', cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          />
        </Box>
        {!matchDownMd && (
          <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                ...theme.typography.menuButton,
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <a href={siteInfo.document_address} target="_blank" style={{ textDecoration: 'none' }}>
        <Typography variant="subtitle2" color="#000022D9" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
          <img src={question} alt="question" style={{ verticalAlign: 'top', width: '18px', height: '20px', marginRight: '5px' }} />
          文档中心
        </Typography>
      </a>

      <img
        width={25}
        height={25}
        style={{ marginLeft: '10px', objectFit: 'contain', cursor: 'pointer' }}
        src={handleRightsIcon()}
        onClick={() => shipRights()}
      />

      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
