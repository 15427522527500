// assets
import {
  IconChartArea,
  IconMessage,
  IconPictureInPicture,
  IconChartDots,
  IconCertificate,
  IconChartBar,
  IconDeviceLaptop,
  IconTicket,
  IconServer,
  IconUserEdit,
  IconReload,
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconLayoutGrid,
  IconDevicesPlus,
  IconBoxModel,
  IconTag,
  IconSettingsBolt,
  IconDeviceAudioTape,
  IconTemplate,
  IconRobot,
  IconDatabaseLeak,
  IconLockSquareRounded,
  IconLibrary,
  IconList
} from '@tabler/icons-react';
import { filterMenu } from 'layout/utils';
import { getUserInfo, isShowFinetuneMenu } from 'utils/common';

// constant
const icons = {
  IconDashboard,
  IconLayoutGrid,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconDevicesPlus,
  IconChartArea,
  IconTemplate,
  IconRobot,
  IconDatabaseLeak
};

const getPanel = () => {
  const roleList = getUserInfo()?.roleInfo;
  const permissions = Array.isArray(roleList?.permissions) ? roleList.permissions.map((e) => e.name) : [];
  const panel = {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: '用户总览',
        type: 'item',
        url: '/panel/dashboard/index',
        icon: icons.IconDashboard,
        breadcrumbs: false,
        isAdmin: false,
        group: '',
        key: 'UserOverview'
      },

      {
        id: 'exploration_center',
        title: '体验中心',
        type: 'collapse',
        icon: IconDeviceLaptop,
        breadcrumbs: false,
        isAdmin: false,
        children: [
          {
            id: 'chat_bot',
            title: '对话聊天',
            type: 'item',
            url: '/panel/exploration_center/index',
            icon: IconMessage,
            breadcrumbs: false,
            isAdmin: false,
            key: 'ExperienceCenter-Chat'
          },
          {
            id: 'text2image',
            title: '文生图',
            type: 'item',
            url: '/panel/exploration_center/text2img/index',
            icon: IconPictureInPicture,
            breadcrumbs: false,
            isAdmin: false,
            key: 'ExperienceCenter-TextGeneratedImage'
          },
          {
            id: 'text2language',
            title: '文生语音',
            type: 'item',
            url: '/panel/exploration_center/text2voice/index',
            icon: IconDeviceAudioTape,
            breadcrumbs: false,
            isAdmin: false,
            key: 'ExperienceCenter-TextGeneratedVoice'
          }
        ],
        group: '模型'
      },
      {
        id: 'modelsquare',
        title: '模型广场',
        type: 'item',
        url: '/panel/modelsquare/index',
        icon: icons.IconLayoutGrid,
        breadcrumbs: false,
        isAdmin: false,
        group: '模型',
        key: 'ModelSquare'
      },
      {
        id: 'usage/index',
        title: '用量统计',
        type: 'item',
        url: '/panel/usage/index',
        icon: IconChartBar,
        breadcrumbs: false,
        isAdmin: false,
        group: '模型',
        key: 'DosageStatistics'
      },
      {
        id: 'token',
        title: '令牌管理',
        type: 'item',
        url: '/panel/token/index',
        icon: icons.IconKey,
        breadcrumbs: false,
        group: '模型',
        key: 'TokenManagement'
      },

      {
        id: 'agent',
        title: '智能体',
        type: 'item',
        url: 'agent/index',
        icon: icons.IconRobot,
        breadcrumbs: false,
        isAdmin: false,
        group: '智能',
        key: 'Agent'
      },
      {
        id: 'datasets',
        title: '知识库',
        type: 'item',
        url: 'datasets/index',
        icon: icons.IconDatabaseLeak,
        breadcrumbs: false,
        isAdmin: false,
        group: '智能',
        key: 'KnowledgeBase'
      },
      {
        id: 'cuewordproject',
        title: '提示词优化',
        type: 'item',
        url: 'cueWordproject/index',
        icon: icons.IconTemplate,
        breadcrumbs: false,
        isAdmin: false,
        group: '智能',
        key: 'PromptWordOptimization'
      },
      {
        id: 'model-adjustment',
        title: '模型微调',
        type: 'item',
        url: '/panel/model/fine_tuning_pro',
        icon: IconSettingsBolt,
        breadcrumbs: false,
        isAdmin: false,
        disabled: false,
        group: '训练',
        key: 'ModelFineTuning'
      },
      {
        id: 'model-deploy',
        title: '模型服务',
        type: 'item',
        url: '/panel/model/fine_service',
        icon: IconServer,
        breadcrumbs: false,
        disabled: false,
        isAdmin: false,
        group: '训练',
        key: 'ModelService'
      },
      {
        id: 'data-reflux/index',
        title: '数据回流',
        type: 'item',
        url: '/panel/data-reflux/index',
        icon: IconReload,
        breadcrumbs: false,
        isAdmin: false,
        group: '数据',
        key: 'DataReflux'
      },
      {
        id: 'large-screen-model/',
        title: '数据管理',
        type: 'collapse',
        url: '/panel/large-screen/model/',
        icon: IconLibrary,
        breadcrumbs: false,
        isAdmin: false,
        group: '大屏',
        key: 'LargeScreenModel',
        children: [
          {
            id: 'large-screen-model/index',
            title: '模型概览',
            type: 'item',
            url: '/panel/large-screen/model/index',
            icon: IconLibrary,
            breadcrumbs: false,
            isAdmin: false,
            group: '大屏',
            key: 'LargeScreenModel'
          }
        ]
      },
      {
        id: 'large-screen-list/index',
        title: '大屏列表',
        type: 'item',
        url: '/panel/large-screen/list/index',
        icon: IconList,
        breadcrumbs: false,
        isAdmin: false,
        group: '大屏',
        key: 'LargeScreenList'
      },
      {
        id: 'channel',
        title: '模型端点',
        type: 'item',
        url: '/panel/channel/index',
        icon: icons.IconSitemap,
        breadcrumbs: false,
        isAdmin: true,
        group: '运维',
        key: 'TerminalPoint'
      },

      {
        id: 'log',
        title: '模型日志',
        type: 'item',
        url: '/panel/log/index',
        icon: icons.IconArticle,
        breadcrumbs: false,
        isAdmin: true,
        group: '运维',
        key: 'Logs'
      },
      {
        id: 'statistics',
        title: '数据统计',
        type: 'item',
        url: '/panel/statistics/index',
        icon: IconChartDots,
        breadcrumbs: false,
        isAdmin: true,
        group: '运维',
        key: 'DataStatistics'
      },

      {
        id: 'modelsquaremanage',
        title: '模型广场',
        type: 'collapse',
        url: '/panel/modelsquaremanage',
        icon: IconBoxModel,
        breadcrumbs: false,
        isAdmin: true,
        group: '运维',
        key: 'ModelSquareManagement',
        children: [
          {
            id: 'modelsquaremanage-card',
            title: '模型卡片',
            type: 'item',
            url: '/panel/modelsquaremanage/card/index',
            icon: IconBoxModel,
            breadcrumbs: false,
            isAdmin: true,
            group: '运维',
            key: 'ModelSquareManagement'
          },
          {
            id: 'modelsquaremanage-modeltag',
            title: '模型标签',
            type: 'item',
            url: '/panel/modelsquaremanage/modeltag/index',
            icon: IconTag,
            breadcrumbs: false,
            isAdmin: true,
            group: '运维',
            key: 'ModelLabelManagement'
          }
        ]
      },
      {
        id: 'auth-enterprise-manage',
        title: '企业认证管理',
        type: 'item',
        url: '/panel/auth/enterprise/manage/index',
        icon: IconCertificate,
        breadcrumbs: false,
        isAdmin: true,
        group: '运维',
        key: 'EnterpriseCertificationManagement'
      },
      {
        id: 'redemption',
        title: '兑换券',
        type: 'item',
        url: '/panel/redemption/index',
        icon: IconTicket,
        breadcrumbs: false,
        isAdmin: true,
        group: '系统',
        key: 'Exchange'
      },
      {
        id: 'permission',
        title: '权限管理',
        type: 'collapse',
        icon: IconLockSquareRounded,
        breadcrumbs: false,
        isAdmin: true,
        group: '系统',
        key: 'AuthorityManagement',
        children: [
          {
            id: 'role/manage',
            title: '角色管理',
            type: 'item',
            url: '/panel/permission/role/index',
            icon: IconUserEdit,
            breadcrumbs: false,
            isAdmin: true,
            group: '设置',
            key: 'RoleManagement'
          },
          {
            id: 'point/manage',
            title: '权限点管理',
            type: 'item',
            url: '/panel/permission/point/index',
            icon: IconUserEdit,
            breadcrumbs: false,
            isAdmin: true,
            group: '设置',
            key: 'AuthorityPointManagement'
          }
        ]
      },
      {
        id: 'user',
        title: '用户管理',
        type: 'item',
        url: '/panel/user/index',
        icon: icons.IconUser,
        breadcrumbs: false,
        isAdmin: true,
        group: '系统',
        key: 'UserManagement'
      },
      {
        id: 'setting',
        title: '设置',
        type: 'item',
        url: '/panel/setting/index',
        icon: icons.IconAdjustments,
        breadcrumbs: false,
        isAdmin: true,
        isRoot: true,
        group: '系统',
        key: 'Setting'
      }
    ]
  };
  panel.children = filterMenu(panel.children, permissions);
  return panel;
};

export { getPanel };
