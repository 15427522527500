import { usePermission } from 'hooks/usePermission';
import React from 'react';
import { getUserInfo } from 'utils/common';

const PermissionWrapper = ({ requiredPermission, children }) => {
  const roleList = getUserInfo()?.roleInfo;
  const permissions = Array.isArray(roleList?.permissions) ? roleList.permissions.map((e) => e.name) : [];
  // 使用 usePermission hook 判断是否有权限
  const hasPermission = usePermission(permissions, requiredPermission);
  if (!hasPermission) {
    return null; // 没有权限时不渲染内容
  }
  return <>{children}</>;
};

export default PermissionWrapper;
