import { lazy } from 'react';

// project imports
import DocLayout from 'layout/DocLayout';
import Loadable from 'ui-component/Loadable';

// document routing
const Interface = Loadable(lazy(() => import('views/Document/Interface')));
const Text = Loadable(lazy(() => import('views/Document/Interface/text')));
const Image = Loadable(lazy(() => import('views/Document/Interface/image')));
const Audio = Loadable(lazy(() => import('views/Document/Interface/audio')));
const Model = Loadable(lazy(() => import('views/Document/Interface/model')));
const Account = Loadable(lazy(() => import('views/Document/Interface/account')));
const Error = Loadable(lazy(() => import('views/Document/Interface/error')));

const Ticket = Loadable(lazy(() => import('views/Document/Question/ticket')));
const Abnormal = Loadable(lazy(() => import('views/Document/Question/abnormal')));
const Overdue = Loadable(lazy(() => import('views/Document/Question/overdue')));
const Private = Loadable(lazy(() => import('views/Document/Question/private')));
const Vouchers = Loadable(lazy(() => import('views/Document/Question/vouchers')));
const Insufficient = Loadable(lazy(() => import('views/Document/Question/insufficient')));
const Password = Loadable(lazy(() => import('views/Document/Question/password')));
const Captcha = Loadable(lazy(() => import('views/Document/Question/captcha')));
const Website = Loadable(lazy(() => import('views/Document/Question/website')));
const Authentication = Loadable(lazy(() => import('views/Document/Question/authentication')));
const Negative = Loadable(lazy(() => import('views/Document/Question/negative')));
const Deduction = Loadable(lazy(() => import('views/Document/Question/deduction')));

const LogsV1_0_0 = Loadable(lazy(() => import('views/Document/UpdateLog/v1_0_0')));
const LogsV1_0_2 = Loadable(lazy(() => import('views/Document/UpdateLog/v1_0_2')));
const LogsV1_1_0 = Loadable(lazy(() => import('views/Document/UpdateLog/v1_1_0')));
const LogsV1_1_1 = Loadable(lazy(() => import('views/Document/UpdateLog/v1_1_1')));
const LogsV1_1_2 = Loadable(lazy(() => import('views/Document/UpdateLog/v1_1_2')));
const LogsV1_2_0 = Loadable(lazy(() => import('views/Document/UpdateLog/v1_2_0')));

const DocRoutes = {
  path: '/panel',
  element: <DocLayout />,
  children: [

    // {
    //   path: 'text-doc',
    //   element: <Interface />
    // },
    // {
    //   path: 'interface-doc',
    //   element: <Interface />
    // },
    {
      path: 'text-doc',
      element: <Text />
    },
    {
      path: 'img-doc',
      element: <Image />
    },
    {
      path: 'audio-doc',
      element: <Audio />
    },
    {
      path: 'model-doc',
      element: <Model />
    },
    {
      path: 'account-doc',
      element: <Account />
    },
    {
      path: 'error-doc',
      element: <Error />
    },
    
    // 如何开发票
    {
      path: 'ticket-doc',
      element: <Ticket />
    },
    // 账户异常
    {
      path: 'abnormal-doc',
      element: <Abnormal />
    },
    // 余额过期
    {
      path: 'overdue-doc',
      element: <Overdue />
    },
    // 私有化部署
    {
      path: 'private-doc',
      element: <Private />
    },
    // 如何使用兑换券
    {
      path: 'vouchers-doc',
      element: <Vouchers />
    },
    // 余额不足
    {
      path: 'insufficient-doc',
      element: <Insufficient />
    },
    // 密码丢失怎么办？
    {
      path: 'password-doc',
      element: <Password />
    },
    // 手机收不到验证码怎么办？
    {
      path: 'captcha-doc',
      element: <Captcha />
    },
    // 网站打不开
    {
      path: 'website-doc',
      element: <Website />
    },
    // 实名认证
    {
      path: 'authentication-doc',
      element: <Authentication />
    },
    // 为什么账户余额会扣为负数？
    {
      path: 'negative-doc',
      element: <Negative />
    },
    // 可用余额中的扣款顺序
    {
      path: 'deduction-doc',
      element: <Deduction />
    },

    {
      path: 'logsV1.0.0-doc',
      element: <LogsV1_0_0 />
    },
    {
      path: 'logsV1.0.2-doc',
      element: <LogsV1_0_2 />
    },
    {
      path: 'logsV1.1.0-doc',
      element: <LogsV1_1_0 />
    },
    {
      path: 'logsV1.1.1-doc',
      element: <LogsV1_1_1 />
    },
    {
      path: 'logsV1.1.2-doc',
      element: <LogsV1_1_2 />
    },
    {
      path: 'logsV1.2.0-doc',
      element: <LogsV1_2_0 />
    }

  ]
};

export default DocRoutes;
