import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NationalEmblemImg from 'assets/images/national_emblem.png';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid, Link } from '@mui/material';
// import AdminContainer from 'ui-component/AdminContainer';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import { getMenuItems } from 'menu-items/profile';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import { useHeaderVisibility } from '../../hooks/useHeaderVisibility';

// assets
import { IconChevronRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { API } from 'utils/api';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, leftDrawerOpened, siderBarWidth }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: leftDrawerOpened ? (open ? 0 : -(siderBarWidth - 20)) : '0px',
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: leftDrawerOpened ? '20px' : '0px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: leftDrawerOpened ? '20px' : '0px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const location = useLocation();
  const navigation = getMenuItems();
  const shouldDisplayHeader = useHeaderVisibility();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const getUserTeamInfo = async () => {
    const res = await API.get('/api/teams/check_user_status');
    const { success, message, data } = res.data;
    if (success) {
      return res.data;
    } else {
      return {};
    }
  };
  const [user, setUser] = useState(null);
  const loadUser = async () => {
    let res = await API.get(`/api/user/self`);
    const { success, message, data, role } = res.data;
    if (success) {
      const teamInfo = await getUserTeamInfo();
      const updatedUser = { ...data, ...teamInfo, roleInfo: role };
      localStorage.setItem('userinfo', JSON.stringify(updatedUser));
      setUser(updatedUser);
    }
  };
  useEffect(() => {
    loadUser();
  }, []);
  const siderBarWidth = leftDrawerOpened ? drawerWidth : 0;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {shouldDisplayHeader && (
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
            height: '56px',
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.06)',
            zIndex: 99
          }}
        >
          <Toolbar sx={{ py: '17px', pl: '20px' }}>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
      )}
      <Sidebar user={user} drawerOpen={true} drawerToggle={handleLeftDrawerToggle} />
      <Main
        leftDrawerOpened={leftDrawerOpened}
        siderBarWidth={siderBarWidth}
        theme={theme}
        open={leftDrawerOpened}
        style={{
          marginTop: shouldDisplayHeader ? '' : '80px',
          background:
            location.pathname == '/modelsquare'
              ? 'linear-gradient(rgb(230, 234, 254) 10%, rgb(255, 255, 255) 70%)'
              : location.pathname == '/profile/index' || location.pathname == '/profile/topup/index'
              ? '#f5f7ff'
              : 'linear-gradient(#F4F7FF 2%, #FFFFFF 10%)',

          borderRadius: '0px'
        }}
      >
        <div style={{ minHeight: 'calc(100vh - 98px)', display: 'flex', flexDirection: 'column' }}>
          <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
          <AuthGuard>
            <Grid flex={1} style={{ display: 'flex', flexDirection: 'column' }}>
              <Outlet />
            </Grid>
          </AuthGuard>
        </div>
      </Main>
    </Box>
  );
};

export default MainLayout;
