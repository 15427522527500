import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import LoginLayout from 'layout/LoginLayout';

const AuthRegisterUser = Loadable(lazy(() => import('views/Authentication/LoginAuth/RegisterUser')));
const ForgetPassword = Loadable(lazy(() => import('views/Authentication/LoginAuth/ForgetPassword')));
const GitHubOAuth = Loadable(lazy(() => import('views/Authentication/Auth/GitHubOAuth')));
const LarkOAuth = Loadable(lazy(() => import('views/Authentication/Auth/LarkOAuth')));
const ResetPassword = Loadable(lazy(() => import('views/Authentication/Auth/ResetPassword')));
const About = Loadable(lazy(() => import('views/About')));
const NotFoundView = Loadable(lazy(() => import('views/Error')));
const FullScreen = Loadable(lazy(() => import('views/StatisticsPanel/FullScreen/index')));
const TeamInviteUser = Loadable(lazy(() => import('views/TeamManage/InviteUser')));
const Maintain = Loadable(lazy(() => import('views/Maintain')));
const Home = Loadable(lazy(() => import('views/Home')));

const LoginAuth = Loadable(lazy(() => import('views/Authentication/LoginAuth/Login')));

export const LoginRoutes = {
  path: '/',
  element: <LoginLayout />,
  children: [
    {
      path: 'fullScreen/index',
      element: <FullScreen />
    },
    {
      path: '/about/index',
      element: <About />
    },
    {
      path: '/login/index',
      element: <LoginAuth />
    },
    {
      path: '/register/index',
      element: <AuthRegisterUser />
    },
    {
      path: '/reset/index',
      element: <ForgetPassword />
    },
    {
      path: '/user/reset/index',
      element: <ResetPassword />
    },
    {
      path: '/oauth/github/index',
      element: <GitHubOAuth />
    },
    {
      path: '/oauth/lark/index',
      element: <LarkOAuth />
    },
    {
      path: '/404/index',
      element: <NotFoundView />
    }
  ]
};
export const noMainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <Maintain />
    },
    {
      path: '/home',
      element: <Home />
    },
    {
      path: '/panel/team_invite',
      element: <TeamInviteUser />
    }
  ]
};
