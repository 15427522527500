// usePermission.js
import { useMemo } from 'react';

// 工具函数：检查权限组是否包含特定权限
export function hasPermission(userPermissions, requiredPermission) {
  if (!userPermissions || !requiredPermission) {
    return false;
  }
  return userPermissions.includes(requiredPermission);
}

// usePermission Hook
export function usePermission(userPermissions, requiredPermission) {
  const hasAccess = useMemo(() => {
    return hasPermission(userPermissions, requiredPermission);
  }, [userPermissions, requiredPermission]);

  return hasAccess;
}
