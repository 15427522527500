import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Drawer, Stack, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';

const Sidebar = ({ drawerOpen, drawerToggle, window, user }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const siderBarWidth = drawerOpen ? drawerWidth : '72px';
  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>

      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px - 36px)' : 'calc(100vh - 56px - 36px)',
          paddingLeft: drawerOpen ? '10px' : '0px',
          paddingRight: drawerOpen ? '10px' : '0px'
        }}
      >
        <MenuList />
      </PerfectScrollbar>
      {matchUpMd && <MenuCard />}
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          {matchUpMd && <MenuCard />}
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? siderBarWidth : '72px', background: '#f5f7ff' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="persistent"
        anchor="left"
        open={true}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: matchUpMd ? siderBarWidth : '72px',
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '56px',
              borderRight: '1px solid #e5e8f1',
              marginBottom: '36px',
              backgroundColor: '#F4F7FE',
              transition: 'width 0.2s linear'
            },
            zIndex: 98
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
