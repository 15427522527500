import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, fabClasses, StyledEngineProvider } from '@mui/material';
import { SET_THEME } from 'store/actions';
import useAuthRedirect from 'hooks/useAuthRedirect';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import 'highlight.js/styles/xcode.css';
// routing
import Routes from 'routes';
// defaultTheme
// import themes from 'themes';
import themes from 'themes-new'; //修改处

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// auth
import UserProvider from 'contexts/UserContext';
import StatusProvider from 'contexts/StatusContext';
import { SnackbarProvider } from 'notistack';

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  useEffect(() => {
    dispatch({ type: SET_THEME, theme: 'light' });
  }, [dispatch]);
  /**
   * 初始化机器人移动动作
   * 该函数用于实现页面元素的拖动功能。
   * 当用户按下鼠标并移动时，元素会跟随鼠标移动。
   * 当用户释放鼠标时，元素的移动会停止。
   * 此外，还处理了长按拖动时防止触发点击事件的逻辑。
   */
  const initRobootMoveAction = () => {
    const element = document.getElementById('ai_customer_perfxlab');
    if (!element) return;
    let offsetX,
      offsetY,
      isDragging = false;

    element.addEventListener('mousedown', (e) => {
      // 记录鼠标按下时的偏移量
      offsetX = e.clientX - element.offsetLeft;
      offsetY = e.clientY - element.offsetTop;

      // 阻止点击事件的触发
      e.preventDefault();

      // 开始拖动
      isDragging = true;

      // 添加鼠标移动和鼠标释放事件监听器
      document.addEventListener('mousemove', moveElement);
      document.addEventListener('mouseup', stopMovingElement);
    });

    function moveElement(e) {
      isDragging = true;
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging) return;
      // 更新元素的位置
      element.style.left = e.clientX - offsetX + 'px';
      element.style.top = e.clientY - offsetY + 'px';
    }

    function stopMovingElement(e) {
      e.preventDefault();
      e.stopPropagation();
      // 移除鼠标移动和鼠标释放事件监听器
      document.removeEventListener('mousemove', moveElement);
      document.removeEventListener('mouseup', stopMovingElement);
      setTimeout(() => {
        isDragging = false;
      }, 1000);
    }

    // 阻止点击事件，在长按拖动时防止触发点击
    element.addEventListener('click', (e) => {
      // 如果正在拖动，不触发点击事件
      if (isDragging) {
        e.stopPropagation();
        e.preventDefault(); // 阻止点击
      }
    });
  };
  useEffect(() => {
    // 检查 FrontendTracker 是否加载成功
    if (window.DataTrackerClient) {
      // 初始化埋点 SDK
      new window.DataTrackerClient('/tracking/');
    } else {
      console.error("DataTrackerClient SDK not loaded");
    }
    setTimeout(() => {
      initRobootMoveAction();
    }, 1000);
  }, []);

  useAuthRedirect();
  return (
    <ConfigProvider locale={zhCN}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />

          <NavigationScroll>
            <SnackbarProvider autoHideDuration={5000} maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <UserProvider>
                <StatusProvider>
                  <Routes />
                </StatusProvider>
              </UserProvider>
            </SnackbarProvider>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </ConfigProvider>
  );
};

export default App;
