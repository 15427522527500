import { useSelector } from 'react-redux';
import { useEffect, useContext, useState } from 'react';
import { UserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import axios from 'axios';
import { isRoot, getUserInfo } from 'utils/common';

const AuthGuard = ({ children }) => {
  const account = useSelector((state) => state.account);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const hideModel = () => setIsModalOpen(false);
  const goPersonalAuthPage = () => {
    hideModel();
    navigate('/personal/auth');
  };

  const goentErpriseAuthPage = () => {
    hideModel();
    navigate('/enterprise/auth');
  };
  const { isUserLoaded } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isUserLoaded && !account.user) {
      navigate('/login/index');
      return;
    }
    if (!isRoot() && account.user) {
      setTimeout(() => {
        if (account.user?.personal_auth_status == 0 && account.user?.enterprise_auth_status == 0) {
          showModal();
        }
      }, 1000);
    }

    // Dify认证

    const Api_Url_IP = 'https://csca100.llmworld.net:6002';
    const userinfo = getUserInfo();
    let ConsoleToken = localStorage.getItem('console_token');
    let RefreshToken = localStorage.getItem('refresh_token');
    //Agent 认证
    if (!ConsoleToken || !RefreshToken) {
      // 发起请求获取新的 token
      axios
        .post(Api_Url_IP + '/console/api/login', {
          email: userinfo.username + '@example.com',
          password: 'user123123'
        })
        .then((response) => {
          console.log('response', response.data.data.access_token);
          if (response.data.data.access_token && response.data.data.refresh_token) {
            // 将新的 token 存储到 localStorage
            localStorage.setItem('console_token', response.data.data.access_token);
            ConsoleToken = response.data.data.access_token;
            localStorage.setItem('refresh_token', response.data.data.refresh_token);
            RefreshToken = response.data.data.refresh_token;
          } else {
            console.error('Failed to get tokens:', response.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            // 注册账号
            axios
              .post(Api_Url_IP + '/console/api/create_tenant', {
                email: userinfo.username + '@example.com', // 其他注册所需参数
                name: userinfo.username,
                language: 'zh-Hans'
              })
              .then((registerResponse) => {
                console.log('agrnt注册成功');
                if (registerResponse.status === 200) {
                  // 注册成功后再次尝试获取 token
                  return axios.post(Api_Url_IP + '/console/api/login', {
                    email: userinfo.username + '@example.com',
                    password: 'user123123'
                  });
                } else {
                  console.error('Registration failed:', registerResponse.data.data);
                }
              })
              .then((loginResponse) => {
                if (loginResponse.data.data.console_token && loginResponse.data.data.refresh_token) {
                  localStorage.setItem('console_token', loginResponse.data.data.access_token);
                  ConsoleToken = loginResponse.data.data.access_token;
                  console.log('console_token', loginResponse.data.data.access_token);
                  localStorage.setItem('refresh_token', loginResponse.data.data.refresh_token);
                  RefreshToken = loginResponse.data.data.refresh_token;

                  console.log('agrnt认证成功');
                } else {
                  console.error('Failed to get tokens after registration:', loginResponse.data);
                }
              })
              .catch((error) => {
                console.log('agrnt认证失败');
                console.error('Error during registration or login:', error);
              });
          } else {
            console.error('Error fetching tokens:', error);
          }
        });
    } else {
      axios
        .post(Api_Url_IP + '/console/api/refresh-token', {
          refresh_token: RefreshToken //长token认证
        })
        .then((response) => {
          if (response.data.data.access_token && response.data.data.refresh_token) {
            // 将新的 token 存储到 localStorage
            localStorage.setItem('console_token', response.data.data.access_token);
            ConsoleToken = response.data.data.access_token;
            console.log('console_token', response.data.data.access_token);
            localStorage.setItem('refresh_token', response.data.data.refresh_token);
            RefreshToken = response.data.data.refresh_token;

            console.log('agrnt刷新成功');
          }
        })
        .catch((error) => {
          // 发起请求获取新的 token
          axios
            .post(Api_Url_IP + '/console/api/login', {
              email: userinfo.username + '@example.com',
              password: 'user123123'
            })
            .then((response) => {
              console.log('response', response.data.data.access_token);
              if (response.data.data.access_token && response.data.data.refresh_token) {
                // 将新的 token 存储到 localStorage
                localStorage.setItem('console_token', response.data.data.access_token);
                ConsoleToken = response.data.data.access_token;
                console.log('console_token', response.data.data.access_token);
                localStorage.setItem('refresh_token', response.data.data.refresh_token);
                RefreshToken = response.data.data.refresh_token;

                console.log('agrnt认证成功');
              } else {
                console.log('agrnt认证失败');
                console.error('Failed to get tokens:', response.data.data);
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                // 注册账号
                axios
                  .post(Api_Url_IP + '/console/api/create_tenant', {
                    email: userinfo.username + '@example.com', // 其他注册所需参数
                    name: userinfo.username,
                    language: 'zh-Hans'
                  })
                  .then((registerResponse) => {
                    console.log('agrnt注册成功');
                    if (registerResponse.status === 200) {
                      // 注册成功后再次尝试获取 token
                      return axios.post(Api_Url_IP + '/console/api/login', {
                        email: userinfo.username + '@example.com',
                        password: 'user123123'
                      });
                    } else {
                      console.error('Registration failed:', registerResponse.data.data);
                    }
                  })
                  .then((loginResponse) => {
                    if (loginResponse.data.data.console_token && loginResponse.data.data.refresh_token) {
                      localStorage.setItem('console_token', loginResponse.data.data.access_token);
                      ConsoleToken = loginResponse.data.data.access_token;
                      console.log('console_token', loginResponse.data.data.access_token);
                      localStorage.setItem('refresh_token', loginResponse.data.data.refresh_token);
                      RefreshToken = loginResponse.data.data.refresh_token;

                      console.log('agrnt认证成功');
                    } else {
                      console.error('Failed to get tokens after registration:', loginResponse.data);
                    }
                  })
                  .catch((error) => {
                    console.log('agrnt认证失败');
                    console.error('Error during registration or login:', error);
                  });
              } else {
                console.error('Error fetching tokens:', error);
              }
            });
        });
    }
  }, [account, navigate, isUserLoaded]);

  return (
    <>
      <Modal
        zIndex={98}
        centered
        title="提示"
        open={isModalOpen}
        closable={false}
        footer={() => (
          <>
            <Button onClick={() => goPersonalAuthPage()} type="primary">
              个人认证
            </Button>
            <Button onClick={() => goentErpriseAuthPage()} type="primary">
              企业认证
            </Button>
          </>
        )}
      >
        <p>
          亲爱的用户，根据国家相关政策要求，为保障您的账户安全和提供更优质的服务，您需要先完成身份认证。认证过程简单快捷，请放心操作。感谢您的理解与配合！
        </p>
      </Modal>
      {children}
    </>
  );
};

export default AuthGuard;
