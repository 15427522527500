import { API } from 'utils/api';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'store/actions';
import { useNavigate } from 'react-router';
import { showError, showSuccess } from 'utils/common';

import { getJoinTeamBycode } from '../views/TeamManage/api';
const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUserTeamInfo = async () => {
    const res = await API.get('/api/teams/check_user_status');
    const { success, message, data } = res.data;
    if (success) {
      return res.data;
    } else {
      return {};
    }
  };
  const login = async (username, password) => {
    try {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        const teamInfo = await getUserTeamInfo();
        localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
          joinTeam();
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard/index');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };
  const accessTokenLogin = async (access_token) => {
    try {
      const res = await API.post(`/api/user/access_token/login?access_token=${access_token}`);
      const { success, message, data } = res.data;
      if (success) {
        const teamInfo = await getUserTeamInfo();
        localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
          joinTeam();
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard/index');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const githubLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/github?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('绑定成功！');
          navigate('/panel/dashboard/index');
        } else {
          dispatch({ type: LOGIN, payload: data });
          const teamInfo = await getUserTeamInfo();
          localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
          showSuccess('登录成功！');
          joinTeam();
          navigate('/panel/dashboard/index');
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const larkLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/lark?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('绑定成功！');
          navigate('/panel/dashboard/index');
        } else {
          dispatch({ type: LOGIN, payload: data });
          const teamInfo = await getUserTeamInfo();
          localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
          showSuccess('登录成功！');
          joinTeam();
          navigate('/panel/dashboard/index');
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const wechatLogin = async (code) => {
    try {
      const res = await API.get(`/api/oauth/wechat?code=${code}`);
      const { success, message, data } = res.data;
      if (success) {
        dispatch({ type: LOGIN, payload: data });
        const teamInfo = await getUserTeamInfo();
        localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
        showSuccess('登录成功！');
        joinTeam();
        navigate('/panel/dashboard/index');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const logout = async () => {
    await API.get('/api/user/logout');
    let userinfo = {};
    try {
      userinfo = JSON.parse(localStorage.getItem('userinfo'));
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem('userinfo');
    localStorage.removeItem('console_token');
    localStorage.removeItem('refresh_token');
    if (userinfo?.isSuperLogin) {
      window.location.href = 'https://www.scnet.cn/ui/app/platform';
    } else {
      dispatch({ type: LOGIN, payload: null });
    }
    navigate('/');
  };
  const phoneLogin = async (phone, sms_code) => {
    try {
      const res = await API.post(`/api/user/phone/login`, {
        phone,
        sms_code
      });
      const { success, message, data } = res.data;
      if (success) {
        getUserTeamInfo();
        const teamInfo = await getUserTeamInfo();
        localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
          joinTeam();
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard/index');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };
  const superLogin = async (token) => {
    try {
      const res = await API.get(`/api/user/super/login?token=${token}`);
      const { success, message, data } = res.data;
      if (success) {
        getUserTeamInfo();
        const teamInfo = await getUserTeamInfo();
        localStorage.setItem('userinfo', JSON.stringify({ ...data, ...teamInfo }));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard/index');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const joinTeam = async () => {
    try {
      const inviteCode = localStorage.getItem('inviteCode');
      if (!inviteCode) return;
      const res = await getJoinTeamBycode(inviteCode);
      const { success, message, loginState } = res.data;
      if (loginState && success) {
        localStorage.removeItem('inviteCode');
      }
    } catch (err) {}
  };

  return { login, logout, githubLogin, wechatLogin, larkLogin, phoneLogin, superLogin, accessTokenLogin };
};

export default useLogin;
