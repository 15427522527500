import { getUserInfo, isShowTeamMenu } from 'utils/common';
import { IconUsers, IconClipboardData, IconFileDescription, IconCoinYen, IconUser, IconBrandMyOppo } from '@tabler/icons-react';
import { filterMenu } from 'layout/utils';
const getProfileMenu = () => {
  const roleList = getUserInfo()?.roleInfo;
  const permissions = Array.isArray(roleList?.permissions) ? roleList.permissions.map((e) => e.name) : [];
  const panel = {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'profile',
        title: '账户设置',
        type: 'item',
        url: '/profile/index',
        icon: IconUser,
        breadcrumbs: false,
        isAdmin: false,
        key: 'AccountSetting'
      },
      {
        id: 'topup',
        title: '充值兑换',
        type: 'item',
        url: '/profile/topup/index',
        icon: IconCoinYen,
        breadcrumbs: false,
        key: 'RechargeExchange'
      },
      {
        id: 'order',
        title: '账单明细',
        type: 'item',
        url: '/profile/order/manage/index',
        icon: IconFileDescription,
        breadcrumbs: false,
        key: 'BillingDetail'
      },
      {
        id: 'consume',
        title: '账单消耗',
        type: 'item',
        url: '/profile/consume/index',
        icon: IconClipboardData,
        breadcrumbs: false,
        key: 'BillConsumption'
      },
      ...(isShowTeamMenu()
        ? [
            {
              id: 'team_manage',
              title: '我的团队',
              type: 'item',
              url: '/profile/team_manage',
              icon: IconUsers,
              breadcrumbs: false,
              isAdmin: false,
              key: 'MyTeam'
            }
          ]
        : []),
      {
        id: 'rights',
        title: '我的权益',
        type: 'item',
        url: '/profile/rights',
        icon: IconBrandMyOppo,
        breadcrumbs: false,
        key: 'MyInterest'
      }
    ]
  };
  panel.children = filterMenu(panel.children, permissions);
  return panel;
};

export { getProfileMenu };
