import React, { useEffect, useState } from 'react';
import { Divider, Button, Space } from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';
import styles from './index.module.scss';
import { getDocMenu } from 'menu-items/doc_menu';
import { useNavigate } from 'react-router';
const Method = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState({});
  useEffect(() => {

    const fullUrl = window.location.href;
    const urlObj = new URL(fullUrl);
    const pathPart = urlObj.pathname;
    const result = findPrevAndNextByUrl(pathPart);
    setPage(result)
  }, [window.location.href]);


  function findPrevAndNextByUrl(targetUrl) {
    const data = getDocMenu();
    let prev = null;
    let next = null;
    let targetFound = false;

    function recursiveTraversal(arr) {
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        if (element.type === 'item') {
          if (element.url === targetUrl) {
            targetFound = true;
            prev = arr[i-1];
          } else if (targetFound && next === null) {
            next = element;
            return; // 找到目标元素以及其前后元素后，结束遍历
          }
        } else if (element.children && element.children.length > 0) {
          recursiveTraversal(element.children);
        }
      }
    }

    recursiveTraversal(data.children);
    return { prev, next };
  }



  return (
    <>
      <Divider style={{
        borderColor: '#0000221F',
      }} />
      <Space>
        {page.prev && <Button onClick={() => navigate(page.prev.url)} icon={<DoubleLeftOutlined className={styles.arrowIcon} />}>{page.prev.title}</Button>}
        {page.next && <Button onClick={() => navigate(page.next.url) } icon={<DoubleRightOutlined className={styles.arrowIcon} />} iconPosition={'end'}>{page.next.title}</Button>}
      </Space>
    </>
  );
};

export default Method;